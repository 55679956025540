import {
  deepCopy, isEmptyString, isNull, isString, removeEmptyArrayValues
} from '@/utils'
import { MissingRequiredValueError } from '@/utils/errors'

/**
 * Rooof property de/serializer.
 */
const rooofProperty = {

  deserialize: property => {
    if (!property) {
      throw new MissingRequiredValueError('property')
    }
    const propertyCopy = deepCopy(property)

    // Convert `extra` into string
    propertyCopy.extra = JSON.stringify(propertyCopy.extra)

    return propertyCopy
  },

  serialize: property => {
    if (!property) {
      throw new MissingRequiredValueError('property')
    }
    const propertyCopy = deepCopy(property)

    // Zoho ids are unique, so they need to be null rather than empty string
    if (isEmptyString(propertyCopy.zoho_customer_id)) {
      propertyCopy.zoho_customer_id = null
    }
    if (isEmptyString(propertyCopy.zoho_recurring_invoice_id)) {
      propertyCopy.zoho_recurring_invoice_id = null
    }

    // DecimalFields do not permit the empty string, we need to use null instead
    if (propertyCopy.hasOwnProperty('product_subscriptions')) {
      for (const subscription of propertyCopy.product_subscriptions) {
        if (isEmptyString(subscription.discount)) {
          subscription.discount = null
        }
      }
    }
    if (propertyCopy.hasOwnProperty('feature_subscriptions')) {
      for (const subscription of propertyCopy.feature_subscriptions) {
        if (isEmptyString(subscription.discount)) {
          subscription.discount = null
        }
      }
    }

    // Convert null values to empty strings
    if (isNull(propertyCopy.type)) {
      propertyCopy.type = ''
    }

    // Remove empty values and trim leading/training whitespace from string arrays
    const fields = [
      'floor_plan_urls'
    ]
    removeEmptyArrayValues(propertyCopy, fields)

    // Convert `extra` back into JSON
    if (propertyCopy.hasOwnProperty('extra') && isString(propertyCopy.extra)) {
      if (isEmptyString(propertyCopy.extra)) {
        propertyCopy.extra = {}
      } else {
        try {
          propertyCopy.extra = JSON.parse(propertyCopy.extra)
        } catch (err) {
          throw new Error('Invalid JSON data for property: `extra`')
        }
      }
    }

    return propertyCopy
  }
}

export default rooofProperty
