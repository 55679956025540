/**
 * Extendable error class which allows for a custom
 * error name and message.
 */
export class ExtendableError extends Error {
  constructor (message = '') {
    super(message)

    Object.defineProperty(this, 'message', { value: message })
    Object.defineProperty(this, 'name', { value: this.constructor.name })

    if (Error.hasOwnProperty('captureStackTrace')) {
      Error.captureStackTrace(this, this.constructor)
    } else {
      Object.defineProperty(this, 'stack', (new Error(message)).stack)
    }
  }
}

export class MissingRequiredValueError extends ExtendableError {
  constructor (value) {
    super(`Missing a required value (${value})`)

    // FIXME: Temporary workaround for https://babeljs.io/docs/en/caveats/#classes
    this.constructor = MissingRequiredValueError
    /* eslint-disable no-proto */
    this.__proto__ = MissingRequiredValueError.prototype
  }
}

export class InvalidDateError extends ExtendableError {
  constructor (value) {
    super(`Date/string is not a valid date (${value})`)

    this.constructor = MissingRequiredValueError
    this.__proto__ = MissingRequiredValueError.prototype
  }
}
