import Constant from './base'

/**
 * Shared Rooof constants.
 */
class Status extends Constant {
  static get ACTIVE () { return 'active' }
  static get INACTIVE () { return 'inactive' }
  static get ONBOARDING () { return 'onboarding' }
  static get OFFBOARDING () { return 'offboarding' }
  static get PENDING () { return 'pending' }
  static get PRETRIAL () { return 'pre-trial' }
  static get TRIAL () { return 'trial' }
  static get INVALID () { return 'invalid' }
  static get AUTO_CREATED () { return 'auto-created' }
}

class BillingFrequency extends Constant {
  static get MONTHLY () { return 1 }
  static get QUARTERLY () { return 3 }
  static get SEMI_ANUALLY () { return 6 }
  static get ANNUALLY () { return 12 }
}

class Features extends Constant {
  static get POSTING () { return 'Automated Posting' }
  static get RENEWS () { return 'Automatic Ad Renewal' }
  static get PRICE_UPDATING () { return 'Price Updating' }
  static get PHONE () { return 'Lead Tracking (phone)' }
  static get EMAIL () { return 'Lead Tracking (email)' }
  static get REDIRECT_LINKS () { return 'Lead Tracking (website)' }
  static get SCHEDULED_POSTING () { return 'Scheduled Posting' }
  static get POSTING_SERVICE () { return 'Posting Service' }
}

// This must remain pascal case to match route names
class ReportTypes extends Constant {
  static get OverviewReport () {
    return {
      title: 'Overview Report',
      feature: '',
      link: ''
    }
  }

  static get EmailReport () {
    return {
      title: 'Email Reports',
      feature: Features.EMAIL,
      link: 'https://www.postengine.com/email-handling'
    }
  }

  static get PhoneReport () {
    return {
      title: 'Phone Reports',
      feature: Features.PHONE,
      link: 'https://www.postengine.com/call-tracking'
    }
  }

  static get WebsiteTrafficReport () {
    return {
      title: 'Website Traffic Report',
      feature: Features.REDIRECT_LINKS,
      link: 'https://www.postengine.com/reporting'
    }
  }

  static get PostingActivityReport () {
    return {
      title: 'Posted Ads',
      feature: Features.POSTING,
      link: 'https://www.postengine.com/automated-posting'
    }
  }
}

class Task extends Constant {
  static get STATES () {
    return [
      { value: 'successful', label: 'Successful' },
      { value: 'active', label: 'Active' },
      { value: 'failed', label: 'Failed' },
      { value: 'expired', label: 'Expired' },
      { value: 'cancelled', label: 'Cancelled' }
    ]
  }

  static get FAILURE_REASONS () {
    return [
      { value: 'identical_price', label: 'Identical Price' },
      { value: 'login_failure', label: 'Login Failure' },
      { value: 'ad_expired', label: 'Ad Expired' },
      { value: 'unknown_error', label: 'Unknown Error' },
      { value: 'ad_flagged', label: 'Ad Flagged' },
      { value: 'update_not_needed', label: 'Update Not Needed' },
      { value: 'user_closed_tab', label: 'User Closed Tab' },
      { value: 'price_difference_too_large', label: 'Price Difference Too Large' },
      { value: 'session_mismatch', label: 'Session Mismatch' },
      { value: 'ad_not_found', label: 'Ad Not Found' },
      { value: 'posting_timeout_slow_network', label: 'Slow Network' },
      { value: 'ad_deleted', label: 'Ad Deleted' },
      { value: 'posting_timeout', label: 'Posting Timeout' },
      { value: 'inappropriate_credentials', label: 'Inappropriate Credentials' },
      { value: 'publisher_server_error', label: 'Publisher Server Error' },
      { value: 'publisher_validation_error', label: 'Publisher Validation Error' }
    ]
  }
}

export default Object.freeze({
  Status,
  BillingFrequency,
  Features,
  ReportTypes,
  Task
})
