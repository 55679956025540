import { formatDate } from '@/utils'

function formatDateTime (value, formatString) {
  if (!value) {
    return ''
  }
  value = value.toString()
  return formatDate(new Date(value), formatString)
}

/**
 * Converts a duration in ms or s to a human readable format
 *
 * @param {Number} value - duration in ms or s
 * @param {String} unit - unit of time for value
 * @returns {String}
 */
export function formatDuration (value, unit, emptyValue = '') {
  if (!unit || !['ms', 's'].includes(unit)) {
    throw new Error('formatDuration: Invalid `unit` provided')
  }

  if (!value) {
    return emptyValue
  }

  if (unit === 'ms') {
    value = Math.floor(value / 1000)
  }

  const units = {
    h: Math.floor(value / 3600),
    m: Math.floor(value % 3600 / 60),
    s: value % 60
  }

  let timeStr = ''
  for (const [unitLabel, quantity] of Object.entries(units)) {
    timeStr += quantity ? `${quantity}${unitLabel} ` : ''
  }

  return timeStr.trim()
}

export default {
  install (Vue) {
    Vue.filter('formatDateTime', formatDateTime)
    Vue.filter('formatDuration', formatDuration)
  }
}
