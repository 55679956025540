import craigslistProperty from './craigslist/property'
import activateProperty from './rooof/activateProperty'
import rooofProperty from './rooof/property'

const serializers = {
  rooof: {
    property: rooofProperty,
    activateProperty: activateProperty
  },
  craigslist: {
    property: craigslistProperty
  }
}

export default serializers
