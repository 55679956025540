import SecureLS from 'secure-ls'
import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'

// Modules
import authModule from './modules/auth'
import craigslistModule from './modules/craigslist'
import errorModule from './modules/error'
import notificationModule from './modules/notification'
import reportingModule from './modules/reporting'
import rooofModule from './modules/rooof'
import rootModule from './modules/root'
import contactSupportModule from './modules/support'

Vue.use(Vuex)

let storage = window.localStorage

// Encrypt local storage in production
if (process.env.NODE_ENV === 'production') {
  const secureLS = new SecureLS({
    encodingType: 'aes',
    isCompression: false
  })
  storage = {
    getItem: key => secureLS.get(key),
    setItem: (key, value) => secureLS.set(key, value),
    removeItem: key => secureLS.remove(key)
  }
}

/**
 * This plugin is used to cache the vuex state in localStorage
 * so that it will persist across hard page refreshes.
 */
const persistedStatePlugin = createPersistedState({
  key: 'store',
  paths: [
    'root',
    'auth',
    'craigslist',
    'reporting',
    'rooof'
  ],
  storage
})

export default new Vuex.Store({
  strict: process.env.NODE_ENV !== 'production',
  plugins: [
    persistedStatePlugin
  ],
  modules: {
    root: rootModule,
    auth: authModule,
    craigslist: craigslistModule,
    notification: notificationModule,
    reporting: reportingModule,
    rooof: rooofModule,
    support: contactSupportModule,
    error: errorModule
  }
})
