import APIBuilder from '@/services/api/builder'

const InvoicesAPI = {
  customers: new APIBuilder({
    baseUrl: 'invoices/customers',
    endpoints: [
      { name: 'create' }
    ]
  }).generateSchema(),

  bankAccounts: new APIBuilder({
    baseUrl: 'invoices/bank-accounts',
    endpoints: [
      { name: 'list' }
    ]
  }).generateSchema(),

  payments: new APIBuilder({
    baseUrl: 'invoices/payments',
    endpoints: [
      { name: 'create' }
    ]
  }).generateSchema(),

  invoicingGroups: new APIBuilder({
    baseUrl: 'invoices/invoicing-groups',
    endpoints: [
      { name: 'retrieve' },
      { name: 'partialUpdate' }
    ]
  }).generateSchema(),

  recurringInvoices: new APIBuilder({
    baseUrl: 'invoices/recurring_invoices',
    endpoints: [
      { name: 'sync', method: 'POST', path: 'sync' }
    ]
  }).generateSchema()
}

export default InvoicesAPI
