import APIBuilder from '@/services/api/builder'

const RooofAccountAPI = {
  users: new APIBuilder({
    baseUrl: 'accounts/users',
    endpoints: [
      { name: 'me', method: 'get', path: 'me' },
      { name: 'permissions', method: 'get', path: ':id/object-permissions', idRequired: true }
    ]
  }).generateSchema()
}

export default RooofAccountAPI
