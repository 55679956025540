import { format, parseISO } from 'date-fns'

import { InvalidDateError, MissingRequiredValueError } from './errors'

/**
 * Given a value, determine if it's a Date.
 *
 * @param {*} value
 * @returns {Boolean}
 */
export function isDate (value) {
  return value instanceof Date
}

/**
 * Given a Date, determine if it's valid.
 *
 * @param {Date} date
 * @returns {Boolean}
 */
export function isValidDate (date) {
  return isDate(date) && !isNaN(date.getTime())
}

/**
 * Given a date string, parse it into a Date object.
 *
 * @param {String} value - ISO 8601 formatted date string
 * @returns {Date}
 */
export function parseDateString (value) {
  const date = parseISO(value)
  if (!isValidDate(date)) {
    throw new InvalidDateError(value)
  }
  return date
}

/**
 * Given a Date object or ISO string, parse
 * it into a string representation with the
 * given format.
 *
 * See https://date-fns.org/v2.10.0/docs/format
 * for valid format strings.
 *
 * @param {Date|String} date
 * @param {String} fmt - format string
 * @returns {String}
 */
export function formatDate (date, fmt) {
  if (!fmt) {
    throw new MissingRequiredValueError('fmt')
  }
  if (!isDate(date)) {
    date = parseDateString(date)
  }
  return format(date, fmt)
}
