/**
 * Utility for charts
 */

import { format, getDate, getDay, isSameDay, parseISO } from 'date-fns'

/**
 * Returns chart color options.
 *
 * @returns {Object}
 */
export function getChartColorOptions () {
  return {
    darkblue: '#2F4E7C',
    lightblue: '#008FFB',
    teal: '#02C0C1',
    green: '#03E397',
    yellow: '#FFC21D',
    orange: '#FF963F',
    red: '#FF6565',
    purple: '#AC79C1'
  }
}

/**
 * Given a data type, return the corresponding hex color.
 *
 * @param {String} dataType
 * @returns {String}
 */
export function getChartColor (dataType) {
  const colors = getChartColorOptions()
  const colorMap = {
    posts: colors.lightblue,
    emails: colors.lightblue,
    visits: colors.darkblue,
    leads: colors.green,
    calls: colors.green,
    renews: colors.green
  }
  return colorMap[dataType.toLowerCase()]
}

/**
 * Given a list of data types, return the corresponding ordered hex colors.
 *
 * @param {Array} dataTypes
 * @returns {Array}
 */
export function getChartColorSet (dataTypes) {
  const colors = []
  for (const dataType of dataTypes) {
    const color = getChartColor(dataType)
    if (color) {
      colors.push(color)
    }
  }
  for (const fillColor of Object.values(getChartColorOptions())) {
    if (dataTypes.length === colors.length) {
      return colors
    }
    if (!colors.includes(fillColor)) {
      colors.push(fillColor)
    }
  }
  return colors
}

/**
 * Given a dataset of hourly data, return daily totals
 * for each day in the date range.
 *
 * The API will only return data for days where any exists,
 * but the series has a 1-1 mapping with the xaxis labels
 * (each day of the selected range) so we need to fill in
 * any missing days with 0 values.
 *
 * Example dataset:
 *
 * {
 *   '2020-01-01': {
 *     '16': 1,
 *     '18': 3
 *   },
 *   '2020-01-03': {
 *     '09' : 1
 *   }
 * }
 *
 * @param {Object} dataset
 * @param {Array} days
 * @returns {Array}
 */
export function getDailyReportNumbers (dataset, days) {
  const data = []
  // Sum daily totals
  const dates = Object.keys(dataset).map(date => {
    return {
      date: parseISO(date),
      total: Object.values(dataset[date]).reduce((total, count) => total + count, 0)
    }
  })
  // Create series, adding 0's for any missing entries
  for (const day of days) {
    const date = dates.find(el => isSameDay(el.date, day))
    data.push(date ? date.total : 0)
  }
  return data
}

/**
 * Returns the chart's x-axis labels based on the number of days.
 * Less than 90 days = label for each day
 * Between 90-180 days = label for each Sunday of the week
 * More than 180 days = label for the 1st day of each month
 *
 * @params {Array} days - array of dates
 * @retruns {Array} - array of x-axis labels
 */
export function getChartLabels (days, weekBreakpoint = 90, monthBreakpoint = 180) {
  const numberOfDays = days.length
  if (numberOfDays > monthBreakpoint) {
    return days.map(day => getDate(day) === 1 ? format(day, 'dd-MMM') : '')
  } else if (numberOfDays > weekBreakpoint) {
    return days.map(day => getDay(day) === 1 ? format(day, 'dd-MMM') : '')
  }
  return days.map(day => format(day, 'dd-MMM'))
}

/**
 * Calculates the max Y axis and tick amount in order to
 * get a scale of 5's.
 * https://apexcharts.com/docs/options/yaxis/
 *
 * @param {Number} max - maximum y axis value
 * @param {Object}
 */
export function getYAxisScale (max) {
  // round up max value to next multiple of 5
  let maxYAxis = Math.ceil(max / 5) * 5
  let yTickAmount = 0

  // if max y <= 10, just show all the ticks
  if (maxYAxis <= 10) {
    yTickAmount = maxYAxis
    return { maxYAxis, yTickAmount }
  }

  // arbitrary max # of ticks given the vertical limitations of the chart
  const maxTicks = 9

  // set initial tick and step values
  let step = 5
  const ticks = Math.ceil(maxYAxis / step)

  // calculate optimal step size (e.g. 5, 10, 15, ...)
  var stepMultiplier = 1
  while (ticks / stepMultiplier > maxTicks) {
    stepMultiplier++
  }
  step = step * stepMultiplier

  // get new max Y value and number of ticks
  yTickAmount = Math.ceil(maxYAxis / step)
  maxYAxis = Math.ceil(yTickAmount * step)

  return { maxYAxis, yTickAmount }
}
