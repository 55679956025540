/**
 * Root application state.
 *
 * Includes CoreUI state management.
 */
const mutations = {
  RESET_STATE: 'resetState',
  TOGGLE_SIDEBAR_DESKTOP: 'toggleSidebarDesktop',
  TOGGLE_SIDEBAR_MOBILE: 'toggleSidebarMobile',
  SET: 'set',
  TOGGLE: 'toggle',
  CLEAR: 'clear'
}

const initialState = () => {
  return {
    sidebarShow: 'responsive',
    sidebarMinimize: false,
    asideShow: false,
    homepage: null,
    selectedCompany: {
      human_name: '',
      name: '',
      id: null
    },
    selectedProperty: {
      features: {}
    },
    devMode: process.env.NODE_ENV === 'development'
  }
}

const rootModule = {
  namespaced: false,
  state: initialState,
  actions: {
    /**
     * Reset global state.
     *
     * Any module with a `resetState` mutation will be reset
     * unless that module name is present in `exclude` list.
     *
     * @param {Array} exclude - module names to exclude
     */
    resetGlobalState ({ commit }, exclude = []) {
      if (!exclude.includes('root')) {
        commit(mutations.CLEAR, 'selectedCompany')
        commit(mutations.CLEAR, 'selectedProperty')
      }
      // Run on namespaced modules
      for (const mutation in this._mutations) {
        if (mutation.includes('/')) {
          const [ module, name ] = mutation.split('/')
          if (!exclude.includes(module) && name === 'resetState') {
            commit(mutation, null, { root: true })
          }
        }
      }
    }
  },
  mutations: {
    [mutations.RESET_STATE] (state) {
      Object.assign(state, initialState())
    },
    [mutations.TOGGLE_SIDEBAR_DESKTOP] (state) {
      const sidebarOpened = [true, 'responsive'].includes(state.sidebarShow)
      state.sidebarShow = sidebarOpened ? false : 'responsive'
    },
    [mutations.TOGGLE_SIDEBAR_MOBILE] (state) {
      const sidebarClosed = [false, 'responsive'].includes(state.sidebarShow)
      state.sidebarShow = sidebarClosed ? true : 'responsive'
    },
    [mutations.SET] (state, [variable, value]) {
      state[variable] = value
    },
    [mutations.TOGGLE] (state, variable) {
      state[variable] = !state[variable]
    },
    [mutations.CLEAR] (state, variable) {
      const original = initialState()
      state[variable] = original[variable]
    }
  }
}

export default rootModule
