// import store from '@/store'
import rooofProperty from '@/services/api/serializers/rooof/property'
import {
  deepCopy, isEmptyString, isObject, isString, removeEmptyArrayValues
} from '@/utils'
import { MissingRequiredValueError } from '@/utils/errors'

/**
 * Craigslist property de/serializer.
 */
const craigslistProperty = {

  deserialize: property => {
    if (!property) {
      throw new MissingRequiredValueError('property')
    }
    const propertyCopy = deepCopy(property)

    // Convert `extra` to a string
    if (isObject(propertyCopy.extra)) {
      propertyCopy.extra = JSON.stringify(propertyCopy.extra)
    }

    // De-serialize the nested Property object
    propertyCopy.property = rooofProperty.deserialize(propertyCopy.property)

    return propertyCopy
  },
  portalUpdate: {
    serialize: property => {
      if (!property) {
        throw new MissingRequiredValueError('property')
      }
      const propertyCopy = deepCopy(property)

      // Remove empty values and trim leading/training whitespace from string arrays
      const fields = [
        'location', 'title', 'romance_text', 'amenities_property', 'amenities_unit',
        'header', 'footer', 'disclaimer'
      ]
      removeEmptyArrayValues(propertyCopy, fields)

      // Convert `extra` back into JSON
      if (propertyCopy.hasOwnProperty('extra') && isString(propertyCopy.extra)) {
        if (isEmptyString(propertyCopy.extra)) {
          propertyCopy.extra = {}
        } else {
          try {
            propertyCopy.extra = JSON.parse(propertyCopy.extra)
          } catch (err) {
            throw new Error('Invalid JSON data for property `extra`')
          }
        }
      }

      // Serialize the nested Property object
      if (isObject(propertyCopy.property)) {
        propertyCopy.property = rooofProperty.serialize(propertyCopy.property)
      }

      return propertyCopy
    }
  }
}

export default craigslistProperty
