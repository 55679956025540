import store from '@/store'

/**
 * Middleware functions for protecting routes.
 */
export const guards = {
  /**
   * If the user is logged in (has a valid token),
   * continue on the route path, else send them back
   * to the login screen.
   *
   * If a login is required, record the attempted path
   * for redirection after successful authentication.
   */
  requireAuth: (to, from, next) => {
    const location = { name: 'LogIn', query: { redirect: to.fullPath } }
    store.getters['auth/isAuthenticated'] ? next() : next(location)
  },
  /**
   * If the user is staff (has 'staff' oauth scope),
   * continue on the route path, else send them back
   * to their homepage.
   */
  requireStaff: (to, from, next) => {
    store.getters['auth/isStaff'] ? next() : next({ name: 'Home' })
  },
  /**
   * If the user is admin (has 'admin' oauth scope),
   * continue on the route path, else send them back
   * to their homepage.
   */
  requireAdmin: (to, from, next) => {
    store.getters['auth/isAdmin'] ? next() : next({ name: 'Home' })
  },
  /**
   * If the user is authenticated (has a valid token),
   * send them to their homepage, else continue on route
   * path.
   *
   * Used to prevent already authenticated users from
   * accessing specific routes, such as the login page.
   */
  authRedirect: (to, from, next) => {
    store.getters['auth/isAuthenticated'] ? next({ name: 'Home' }) : next()
  },
  /**
   * Prevent users from directly navigating to company pages
   * that they don't have permission for (eg. typing the company
   * id into the url bar).
   */
  requireCompanyPermission: async (to, from, next) => {
    if (!store.state.rooof.companies.length) {
      await store.dispatch('rooof/fetchCompanies')
    }
    store.getters['rooof/getCompanyById'](parseInt(to.params.cid)) ? next() : next({ name: 'Home' })
  },
  /**
   * Prevent users from directly navigating to pages that require the full
   * company endpoint (v2/rooof/companies/:id/) if they don't have sufficient permissions.
   */
  requireFullCompanyPermission: async (to, from, next) => {
    store.getters['auth/hasCompanyPermission'](parseInt(to.params.cid)) ? next() : next({ name: 'Home' })
  },
  /**
   * Prevents users from directly navigating to property pages that require
   * a non-inactive subscription to the posting service.
   */
  requireFullService: async (to, from, next) => {
    const hasFullService = store.state.root.selectedProperty.features.postingService
    hasFullService ? next() : next({ name: 'Home' })
  }
}
