import { construction } from './construction'
import { exporting } from './export'
import { rooofIcon } from './rooof-icon'
import { rooofLogo } from './rooof-logo'
import { wand } from './wand'
import { zohoBooks } from './zoho-books'

export default {
  construction,
  exporting,
  rooofIcon,
  rooofLogo,
  wand,
  zohoBooks
}
