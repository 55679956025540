import Vue from 'vue'
import Router from 'vue-router'

import { guards } from './middleware'

Vue.use(Router)

export const routeConfig = () => {
  return [
    {
      path: '/auth',
      redirect: '/auth/login',
      component: {
        render (c) { return c('router-view') }
      },
      children: [
        {
          path: 'login',
          name: 'LogIn',
          component: () => import('@/views/auth/LogIn'),
          beforeEnter: guards.authRedirect
        },
        {
          path: 'callback',
          name: 'Callback',
          component: () => import('@/views/auth/Callback')
        },
        {
          path: 'logout',
          name: 'LogOut',
          component: () => import('@/views/auth/LogOut')
        }
      ]
    },
    {
      path: '/',
      name: 'Home',
      meta: {
        label: vm => {
          return vm.$store.state.rooof.companies.length > 1 ? 'Home' : null
        }
      },
      component: () => import('@/containers/TheContainer'),
      beforeEnter: guards.requireAuth,
      children: [
        {
          path: 'profile',
          name: 'Profile',
          meta: {
            label: 'Profile'
          },
          component: () => import('@/views/account/Profile')
        },
        {
          path: 'dev',
          name: 'DevTools',
          meta: {
            label: 'Dev Tools'
          },
          component: () => import('@/views/account/DevTools')
        },
        {
          path: 'companies',
          component: {
            render (c) { return c('router-view') }
          },
          children: [
            {
              path: '',
              name: 'CompanyList',
              component: () => import('@/views/companies/company_list/CompanyList')
            },
            {
              path: ':cid',
              meta: {
                label: vm => {
                  return vm.$store.state.root.selectedCompany.human_name
                },
                disableLink: vm => {
                  return vm.$store.getters['rooof/getCompanyById'](vm.$route.params.cid) === undefined
                }
              },
              component: () => import('@/views/companies/BaseCompany'),
              beforeEnter: guards.requireCompanyPermission,
              redirect: ':cid/performance-report',
              children: [
                {
                  path: 'active-properties',
                  name: 'ActivePropertyList',
                  component: () => import('@/views/properties/property_list/ActivePropertyList'),
                  meta: {
                    label: 'Active Properties'
                  }
                },
                {
                  path: 'inactive-properties',
                  name: 'InactivePropertyList',
                  component: () => import('@/views/properties/property_list/InactivePropertyList'),
                  meta: {
                    label: 'Inactive Properties'
                  }
                },
                {
                  path: 'info',
                  name: 'CompanyDetail',
                  beforeEnter: guards.requireFullCompanyPermission,
                  component: () => import('@/views/companies/company_detail/CompanyDetail'),
                  meta: {
                    label: 'Company Info'
                  }
                },
                {
                  path: 'performance-report',
                  name: 'PerformanceReport',
                  component: () => import('@/views/companies/company_performance_report/CompanyPerformanceReport'),
                  meta: {
                    label: 'Performance Report'
                  }
                },
                {
                  path: 'posting-report',
                  redirect: { name: 'PerformanceReport' }
                },
                {
                  path: 'properties/:pid',
                  meta: {
                    label: vm => {
                      return vm.$store.state.root.selectedProperty.name
                    }
                  },
                  component: () => import('@/views/properties/BaseProperty'),
                  children: [
                    {
                      path: '',
                      name: 'PropertyDetail',
                      component: () => import('@/views/properties/property_detail/PropertyDetail')
                    },
                    {
                      path: 'posting-details',
                      name: 'PostingDetail',
                      meta: {
                        label: 'Posting Details'
                      },
                      beforeEnter: guards.requireFullService,
                      component: () => import('@/views/properties/posting_detail/PostingDetail')
                    },
                    {
                      path: 'reporting',
                      name: 'Reporting',
                      redirect: 'reporting/overview',
                      meta: {
                        label: 'Reporting'
                      },
                      component: () => import('@/views/reporting/BaseReport'),
                      children: [
                        {
                          path: 'overview',
                          name: 'OverviewReport',
                          meta: {
                            label: 'Overview'
                          },
                          component: () => import('@/views/reporting/overview/OverviewReport')
                        },
                        {
                          path: 'email',
                          name: 'EmailReport',
                          meta: {
                            label: 'Emails'
                          },
                          component: () => import('@/views/reporting/emails/EmailReport')
                        },
                        {
                          path: 'phone',
                          name: 'PhoneReport',
                          meta: {
                            label: 'Phone Calls'
                          },
                          component: () => import('@/views/reporting/phone/PhoneReport')
                        },
                        {
                          path: 'website-traffic',
                          name: 'WebsiteTrafficReport',
                          meta: {
                            label: 'Website Traffic'
                          },
                          component: () => import('@/views/reporting/redirect_links/RedirectLinksReport')
                        },
                        {
                          path: 'posting-activity',
                          name: 'PostingActivityReport',
                          meta: {
                            label: 'Posted Ads'
                          },
                          component: () => import('@/views/reporting/posting_activity/PostingActivityReport')
                        }
                      ]
                    }
                  ]
                }
              ]
            }
          ]
        },
        {
          path: 'staff',
          component: {
            render (c) { return c('router-view') }
          },
          beforeEnter: guards.requireStaff,
          children: [
            {
              path: '',
              name: 'KPIReports',
              meta: {
                label: 'KPI Report'
              },
              component: () => import('@/views/staff/reporting/kpi/KPIReportBase')
            },
            {
              path: 'zoho',
              redirect: 'zoho/customers',
              meta: {
                label: 'Zoho'
              },
              component: {
                render (c) { return c('router-view') }
              },
              children: [
                {
                  path: 'customers',
                  name: 'PendingZohoCustomer',
                  meta: {
                    label: 'Customers'
                  },
                  component: () => import('@/views/staff/reporting/zoho/PendingZohoCustomer')
                },
                {
                  path: 'invoices',
                  name: 'PendingZohoInvoice',
                  meta: {
                    label: 'Invoices'
                  },
                  component: () => import('@/views/staff/reporting/zoho/PendingZohoInvoice')
                }
              ]
            },
            {
              path: 'monthly-summary',
              meta: {
                label: 'Monthly Summary'
              },
              component: {
                render (c) { return c('router-view') }
              },
              children: [
                {
                  path: '',
                  name: 'MonthlySummary',
                  component: () => import('@/views/staff/reporting/monthly/MonthlySummaryReport')
                },
                {
                  path: ':cid/recurring-invoices',
                  name: 'MonthlySummaryRecurringInvoiceDetails',
                  component: () => import('@/views/staff/reporting/monthly/MonthlySummaryRecurringInvoiceDetails'),
                  meta: {
                    label: vm => {
                      return vm.$store.state.root.selectedCompany.human_name
                    }
                  }
                }
              ]
            },
            {
              path: 'invoice-processing',
              name: 'InvoiceProcessing',
              meta: {
                label: 'Invoice Processing'
              },
              component: () => import('@/views/staff/reporting/invoicing_tools/InvoiceProcessing')
            },
            {
              path: 'price-updating',
              name: 'PriceUpdating',
              redirect: 'price-updating/companies',
              meta: {
                label: 'Price Updating'
              },
              component: {
                render (c) { return c('router-view') }
              },
              children: [
                {
                  path: 'companies',
                  component: () => import('@/views/staff/reporting/price_updating/BaseReport'),
                  children: [
                    {
                      path: '',
                      name: 'PriceUpdatingCompanyList',
                      component: () => import('@/views/staff/reporting/price_updating/CompanyList')
                    },
                    {
                      path: ':cid',
                      meta: {
                        label: vm => {
                          return vm.$store.state.root.selectedCompany.human_name
                        }
                      },
                      component: () => import('@/views/staff/reporting/price_updating/BaseCompany'),
                      children: [
                        {
                          path: '',
                          name: 'PriceUpdatingPropertyList',
                          component: () => import('@/views/staff/reporting/price_updating/PropertyList')
                        },
                        {
                          path: 'properties/:pid',
                          name: 'PriceUpdatingPropertyReport',
                          meta: {
                            label: vm => {
                              return vm.$store.state.root.selectedProperty.name
                            }
                          },
                          component: () => import('@/views/staff/reporting/price_updating/PropertyReport')
                        }
                      ]
                    }
                  ]
                }
              ]
            },
            {
              path: 'subscription-changelog',
              name: 'SubscriptionChangelog',
              meta: {
                label: 'Subscription Changelog'
              },
              component: () => import('@/views/staff/reporting/subscription_changelog/SubscriptionChangelog')
            }
          ]
        }
      ]
    },
    {
      path: '/install',
      redirect: '/install/property-select',
      component: () => import('@/views/install/BaseInstall'),
      children: [
        {
          path: 'property-select',
          name: 'PropertySelect',
          component: () => import('@/views/install/PropertySelect')
        },
        {
          path: 'instructions',
          name: 'InstallInstructions',
          component: () => import('@/views/install/InstallInstructions')
        },
        {
          path: 'invalid',
          name: 'InstallInvalid',
          component: () => import('@/views/install/InstallInvalid')
        }
      ]
    },
    {
      path: '/404',
      alias: '*',
      name: 'Page404',
      component: () => import('@/views/Page404')
    }
  ]
}

const router = new Router({
  mode: 'history',
  linkActiveClass: 'open active',
  scrollBehavior: () => ({ y: 0 }),
  routes: routeConfig()
})

// Temp fix for https://github.com/vuejs/vue-router/issues/2963
router.originalPush = router.push
router.push = async function (location) {
  return router.originalPush(location).catch(err => {
    if (err === undefined || err.name === 'NavigationDuplicated') {
      return this.currentRoute
    }
    throw err
  })
}

export default router
