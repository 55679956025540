export const rooofLogo = [
  '376 178',
  `<g id="layer1">
    <path id="rect4059" class="st0" d="M189.9,42l-44.1,39.6h8.2v17.7c0,19.6,16.1,35.7,35.7,35.7c19.6,0,35.7-16.1,35.7-35.7V81.6h8.2l-19.8-17.9V50.5h-7.5v6.3L189.9,42z M189.7,81.6c9,0,16.5,6.7,17.6,15.4v2.3c0,9.9-7.7,17.6-17.6,17.6c-9.9,0-17.6-7.7-17.6-17.6V97C173.2,88.3,180.7,81.6,189.7,81.6z"/>
    <path id="path4031" class="st0" d="M118.8,72.3c-17.2,0-31.4,14.2-31.4,31.4s14.2,31.4,31.4,31.4s31.4-14.1,31.4-31.4S136.1,72.3,118.8,72.3L118.8,72.3z M118.8,89.2c8,0,14.4,6.4,14.4,14.4s-6.3,14.4-14.4,14.4s-14.4-6.3-14.4-14.4S110.8,89.2,118.8,89.2L118.8,89.2z"/>
    <path id="path4037" class="st0" d="M260.6,72.3c-17.2,0-31.4,14.2-31.4,31.4s14.2,31.4,31.4,31.4c17.2,0,31.4-14.1,31.4-31.4S277.8,72.3,260.6,72.3L260.6,72.3z M260.6,89.2c8,0,14.4,6.4,14.4,14.4s-6.3,14.4-14.4,14.4c-8,0-14.4-6.3-14.4-14.4S252.6,89.2,260.6,89.2L260.6,89.2z"/>
    <path class="st0" d="M67.7,110.8l2.5,0.1c0.9,0,1.9-0.1,2.8-0.2c3.2-0.5,5.8-1.7,7.9-3.7c2.1-2,3.5-4.7,4.2-8.1c0.1-0.5,0.2-1,0.2-1.6c0-0.5,0-1,0-1.6V80.8c0-0.5,0-0.9,0-1.4c0-0.5-0.1-1-0.2-1.5c-0.6-4.1-2.2-7.1-4.7-9.2c-2.5-2.1-6-3.1-10.5-3.1H32.2v69.1h16.1v-24.6c0.8,1.2,1.6,2.2,1.8,2.5L67,134.7h19L67.7,110.8z M65,97.3h-6.5c-5.1,0-8.3,0-10.2-0.1V81.8h16c0.2,0,0.3,0,0.5,0s0.4,0,0.5,0.1c1.4,0.2,2.4,0.7,3,1.4c0.6,0.7,0.9,1.7,0.9,3v6.8c0,1.5-0.3,2.6-1,3.3C67.6,97,66.5,97.3,65,97.3z"/>
    <g id="text3160" transform="matrix(-1,0,0,1,-12.127096,16.619761)">
      <path id="path3168" class="st0" d="M-357.2,64.3v53.8h16.1V94.3h32.6V78.2h-32.6v-8.4c0-0.2,0-0.3,0-0.5c0-0.2,0-0.4,0.1-0.5c0.2-1.2,0.7-2.2,1.5-2.8c0.8-0.6,2.1-0.9,3.9-0.9h27.1V49h-31.8c-3.4,0-6,0.2-7.7,0.6c-1.7,0.5-3.3,1.3-4.6,2.6c-1.5,1.3-2.6,2.9-3.4,4.9C-356.8,59.1-357.2,61.5-357.2,64.3L-357.2,64.3z"/>
    </g>
  </g>`
]
