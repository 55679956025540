import { filterBlankValues } from '@/utils'
import { MissingRequiredValueError } from '@/utils/errors'

/**
 * Iterate through the given object and filter
 * all empty values from the provided array fields.
 *
 * Modifies the object in-place.
 *
 * @param {Object} obj
 * @param {Array} fields - string array fields
 */
export function removeEmptyArrayValues (obj, fields) {
  if (!fields || !fields.length) {
    throw new MissingRequiredValueError('fields')
  }
  for (const field of fields) {
    obj[field] = filterBlankValues(obj[field])
  }
}
