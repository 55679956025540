import '../node_modules/handsontable/dist/handsontable.full.min.css'

import Bugsnag from '@bugsnag/js'
import BugsnagPluginVue from '@bugsnag/plugin-vue'
import CoreuiVue from '@coreui/vue'
import axios from 'axios'
import Mixpanel from 'mixpanel-browser'
import PortalVue from 'portal-vue'
import { DatePicker } from 'v-calendar'
import Vue from 'vue'
import VueApexCharts from 'vue-apexcharts'
import VueAxios from 'vue-axios'

import filters from '@/plugins/filters'

import App from './App'
import icons from './assets/icons'
import router from './router'
import store from './store'

Vue.config.performance = true
Vue.config.productionTip = false

Vue.use(VueAxios, axios)
Vue.use(CoreuiVue)
Vue.use(PortalVue)
Vue.use(VueApexCharts)
Vue.use(filters)

/* eslint-disable vue/match-component-file-name */
Vue.component('v-date-picker', DatePicker)
Vue.component('apex-chart', VueApexCharts)

/**
 * Initialize for Production:
 * Bugsnag - error tracking service
 * Mixpanel - analytics service
 */
if (process.env.NODE_ENV === 'production') {
  Bugsnag.start({
    apiKey: 'c122e440d8b31f7c7de6ad0c0c091468',
    plugins: [new BugsnagPluginVue()]
  })
  Bugsnag.getPlugin('vue').installVueErrorHandler(Vue)
  Mixpanel.init('be328357dc1853b936c001db5fa826c4')
}

if (process.env.NODE_ENV !== 'development') {
  const script = document.createElement('script')
  script.id = 'beacon'
  script.text = '!function(e,t,n){function a(){var e=t.getElementsByTagName("script")[0],n=t.createElement("script");n.type="text/javascript",n.async=!0,n.src="https://beacon-v2.helpscout.net",e.parentNode.insertBefore(n,e)}if(e.Beacon=n=function(t,n,a){e.Beacon.readyQueue.push({method:t,options:n,data:a})},n.readyQueue=[],"complete"===t.readyState)return a();e.attachEvent?e.attachEvent("onload",a):e.addEventListener("load",a,!1)}(window,document,window.Beacon||function(){});'
  document.head.appendChild(script)
  // @ts-ignore
  window.Beacon('init', 'a2dd7bb8-2790-429f-9047-ce8529ca5efd')
}

/**
 * Error handler for uncaught runtime Vue errors.
 *
 * https://vuejs.org/v2/api/#errorHandler
 *
 * @param {Object} err - error trace
 * @param {Object} vm - vue intance
 * @param {Object} info - vue-specific information such as lifecycle hooks, events, etc.
 */
Vue.config.errorHandler = (err, vm, info) => {
  console.error(err)
}

/**
 * Custom handler for runtime Vue warnings.
 * Ignored in production.
 *
 * https://vuejs.org/v2/api/#warnHandler
 *
 * @param {String} msg - warning message
 * @param {Object} vm - vue instance
 * @param {Object} trace - component hierarchy trace
 */
Vue.config.warnHandler = (msg, vm, trace) => {
  console.warn(msg, trace)
}

// eslint-disable-next-line no-new
new Vue({
  router,
  store,
  icons,
  render: h => h(App)
}).$mount('#app')
