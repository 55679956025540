import { RooofAPI } from '@/services/api/resources'

const mutations = {
  RESET_STATE: 'resetState',
  SET: 'set',
  CLEAR: 'clear'
}

const initialState = () => {
  return {
    companies: [],
    products: [],
    features: []
  }
}

const rooofModule = {
  namespaced: true,
  state: initialState,
  getters: {
    getCompanyById: state => cid => {
      return state.companies.find(company => company.id === parseInt(cid, 10))
    },
    getCompanyByName: state => name => {
      return state.companies.find(company => company.name === name)
    }
  },
  actions: {
    /**
     * Fetch the list of Rooof company objects from the api.
     */
    async fetchCompanies ({ commit, state }) {
      const response = await RooofAPI.companies.summary()
      if (response) {
        commit(mutations.SET, ['companies', response.data])
      }

      // Set the homepage. For users with a single company,
      // this will be the company performance report. For users with
      // multiple companies, this will be the list of companies.
      let homepage = { name: 'CompanyList' }
      if (state.companies.length === 1) {
        homepage = { name: 'PerformanceReport', params: { cid: state.companies[0].id } }
      }
      commit(mutations.SET, ['homepage', homepage], { root: true })
    },
    /**
     * Fetch list of products from the api.
     */
    async fetchProducts ({ commit }) {
      const response = await RooofAPI.products.list()
      if (response) {
        commit(mutations.SET, ['products', response.data])
      }
    },
    /**
     * Fetch list of features from the api.
     */
    async fetchFeatures ({ commit }) {
      const response = await RooofAPI.features.list()
      if (response) {
        commit(mutations.SET, ['features', response.data])
      }
    }
  },
  mutations: {
    [mutations.RESET_STATE] (state) {
      Object.assign(state, initialState())
    },
    [mutations.SET] (state, [variable, value]) {
      state[variable] = value
    },
    [mutations.CLEAR] (state, variable) {
      const original = initialState()
      state[variable] = original[variable]
    }
  }
}

export default rooofModule
