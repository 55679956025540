import { mdAccountCircle } from './mdAccountCircle'
import { mdAirCondition } from './mdAirCondition'
import { mdApartment } from './mdApartment'
import { mdArrowBack } from './mdArrowBack'
import { mdBed } from './mdBed'
import { mdCalendarContact } from './mdCalendarContact'
import { mdCancel } from './mdCancel'
import { mdCar } from './mdCar'
import { mdChart } from './mdChart'
import { mdChartOutline } from './mdChartOutline'
import { mdCheckCircle } from './mdCheckCircle'
import { mdChevronRight } from './mdChevronRight'
import { mdCircle } from './mdCircle'
import { mdClose } from './mdClose'
import { mdComputer } from './mdComputer'
import { mdConstruction } from './mdConstruction'
import { mdContentCopy } from './mdContentCopy'
import { mdDescription } from './mdDescription'
import { mdDocument } from './mdDocument'
import { mdDog } from './mdDog'
import { mdDownload } from './mdDownload'
import { mdEdit } from './mdEdit'
import { mdEmail } from './mdEmail'
import { mdError } from './mdError'
import { mdEventNote } from './mdEventNote'
import { mdEvStation } from './mdEvStation'
import { mdExpandMore } from './mdExpandMore'
import { mdHeadset } from './mdHeadset'
import { mdHelp } from './mdHelp'
import { mdHistory } from './mdHistory'
import { mdInfo } from './mdInfo'
import { mdLaundry } from './mdLaundry'
import { mdLock } from './mdLock'
import { mdLockOpen } from './mdLockOpen'
import { mdLogout } from './mdLogout'
import { mdMoney } from './mdMoney'
import { mdMoney2 } from './mdMoney2'
import { mdMouse } from './mdMouse'
import { mdPeople } from './mdPeople'
import { mdPets } from './mdPets'
import { mdPhone } from './mdPhone'
import { mdPhoneCallback } from './mdPhoneCallback'
import { mdPhoneMissed } from './mdPhoneMissed'
import { mdPlace } from './mdPlace'
import { mdPriceChange } from './mdPriceChange'
import { mdRefresh } from './mdRefresh'
import { mdRequestQuote } from './mdRequestQuote'
import { mdSchedule } from './mdSchedule'
import { mdSearch } from './mdSearch'
import { mdSmoking } from './mdSmoking'
import { mdSync } from './mdSync'
import { mdTimer } from './mdTimer'
import { mdTools } from './mdTools'
import { mdUser } from './mdUser'
import { mdVisibility } from './mdVisibility'
import { mdVisibilityOff } from './mdVisibilityOff'
import { mdWarning } from './mdWarning'
import { mdWeb } from './mdWeb'
import { mdWheelchair } from './mdWheelchair'

export default {
  mdAccountCircle,
  mdAirCondition,
  mdApartment,
  mdArrowBack,
  mdBed,
  mdCalendarContact,
  mdCancel,
  mdCar,
  mdChart,
  mdChartOutline,
  mdCheckCircle,
  mdChevronRight,
  mdCircle,
  mdClose,
  mdComputer,
  mdConstruction,
  mdContentCopy,
  mdDescription,
  mdDocument,
  mdDog,
  mdDownload,
  mdEdit,
  mdEmail,
  mdError,
  mdEventNote,
  mdEvStation,
  mdExpandMore,
  mdHeadset,
  mdHelp,
  mdHistory,
  mdInfo,
  mdLaundry,
  mdLock,
  mdLockOpen,
  mdMoney,
  mdMoney2,
  mdMouse,
  mdLogout,
  mdPeople,
  mdPets,
  mdPhone,
  mdPhoneCallback,
  mdPhoneMissed,
  mdPlace,
  mdPriceChange,
  mdRefresh,
  mdRequestQuote,
  mdSchedule,
  mdSearch,
  mdSmoking,
  mdSync,
  mdTimer,
  mdTools,
  mdUser,
  mdVisibility,
  mdVisibilityOff,
  mdWarning,
  mdWeb,
  mdWheelchair
}
