import { nanoid } from 'nanoid'

import router from '@/router'
import { MissingRequiredValueError } from '@/utils/errors'

export const CLIENT_ID = process.env.VUE_APP_AUTH_CLIENT_ID || 'property-portal-v2'
export const AUTHORIZATION_ENDPOINT = process.env.VUE_APP_AUTH_URL

/**
 * Helper function to extract URL params from redirect.
 *
 * @param {String} key - key to match
 */
function getParameterByKey (key) {
  const match = RegExp('[#&]' + key + '=([^&]*)').exec(window.location.hash)
  return match && decodeURIComponent(match[1].replace(/\+/g, ' '))
}

/**
 * Authentication module.
 *
 * This is a utility object that does not hold any internal state.
 * It's only responsible for providing authentication-related
 * methods for use by external modules.
 */
const AuthClient = {
  /**
   * Redirects user to OAuth provider for authentication.
   *
   * If successful, user will be redirected back to the
   * redirect_uri configured in the OAuth client.
   *
   * @param {String} state - OAuth state to be returned in the response
   * @param {String} [scope] - OAuth scope(s) to request
   */
  login (state, scope = 'staff reporting') {
    // This MUST match the redirect_uri set in the OAuth client
    const base = window.location.origin
    const route = router.resolve({ name: 'Callback' }).href

    const params = new URLSearchParams({
      response_type: 'token',
      redirect_uri: base + route,
      client_id: CLIENT_ID,
      scope,
      state
    })

    // Navigate to authorization endpoint so user can authenticate
    window.location.assign(AUTHORIZATION_ENDPOINT + '?' + params.toString())
  },

  /**
   * Redirect user back to login page.
   */
  logout () {
    router.push({ name: 'LogIn' })
  },

  /**
   * Parse and store the token returned from OAuth server.
   *
   * @param {String} storedState - original state value to compare with the response
   * @returns {Object} - token if successful
   */
  parseResponseURI (storedState) {
    const accessToken = getParameterByKey('access_token')
    const expiresIn = getParameterByKey('expires_in')
    const state = getParameterByKey('state')
    const scope = getParameterByKey('scope')
    const error = getParameterByKey('error')

    if (error) {
      throw new Error('OAuth provider was unable to authenticate due to the following: ' + error.toString())
    }
    if (!accessToken) {
      throw new MissingRequiredValueError('access_token')
    }
    if (state !== storedState) {
      throw new Error('State did not match, we\'re under attack!')
    }
    if (!scope) {
      throw new MissingRequiredValueError('scope')
    }

    // Token returns seconds until expiry, convert to Date obj
    const expiresAt = new Date()
    expiresAt.setSeconds(expiresAt.getSeconds() + parseInt(expiresIn, 10))

    const token = {
      access_token: accessToken,
      expires_at: expiresAt.getTime(),
      state,
      scope
    }

    return token
  },

  /**
   * Generate a cryptographically secure random string for use as a nonce.
   *
   * @returns {String}
   */
  generateRandomID () {
    return nanoid()
  }
}

export default AuthClient
