/**
 * Utility for Rooof product/feature subscriptions
 */
import constants from '@/utils/constants'

/**
 * Given a property, return all features (including ones inherited from products)
 * based on the given status criteria.
 *
 * @param {Object} property - Rooof property
 * @param {Function} statusFilter - Function to filter subscriptions by status
 * @returns {Object}
 */
export function getFeatureSet (property, statusFilter = () => true) {
  const features = new Set()
  for (const feature of property.features_list) {
    if (!statusFilter(feature.status)) {
      continue
    }
    features.add(feature.name)
  }
  return features
}

/**
 * Given a property, return all features (including ones inherited from products)
 * with their respective booleans based on the given status criteria.
 *
 * @param {Object} property - Rooof property
 * @param {Function} statusFilter - Function to filter subscriptions by status
 * @returns {Object}
 */
export function getFeatures (property, statusFilter = () => true) {
  const featureSet = getFeatureSet(property, statusFilter)
  return getFeatureMap(featureSet)
}

/**
 * Returns feature map based on given feature set
 *
 * @param {Object} featureSet
 * @returns {Object}
 */
export function getFeatureMap (featureSet) {
  return {
    automatedPosting: featureSet.has(constants.rooof.Features.POSTING),
    automaticRenewal: featureSet.has(constants.rooof.Features.RENEWS),
    priceUpdating: featureSet.has(constants.rooof.Features.PRICE_UPDATING),
    emailHandling: featureSet.has(constants.rooof.Features.EMAIL),
    callTracking: featureSet.has(constants.rooof.Features.PHONE),
    redirectLinks: featureSet.has(constants.rooof.Features.REDIRECT_LINKS),
    scheduledPosting: featureSet.has(constants.rooof.Features.SCHEDULED_POSTING),
    postingService: featureSet.has(constants.rooof.Features.POSTING_SERVICE)
  }
}
