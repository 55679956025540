import { ProblemReportsAPI } from '@/services/api/resources'

const mutations = {
  RESET_STATE: 'resetState',
  SET: 'set'
}

const initialState = () => {
  return {
    show: false,
    loading: false,
    incorrect_profile: false,
    incorrect_company_property_info: false,
    incorrect_property_access: false,
    incorrect_reporting_data: false,
    error_code: false,
    other: false,
    description: '',
    user_email: '',
    user_phone: '',
    labels: {
      incorrect_profile: 'My profile information is incorrect',
      incorrect_company_property_info: 'My company/property information is incorrect',
      incorrect_property_access: "I don't see a property I should have access to",
      incorrect_reporting_data: 'My reporting data looks incorrect',
      error_code: "I'm receiving an error code",
      other: 'Other questions, comments, or suggestions'
    }
  }
}

const contactSupportModule = {
  namespaced: true,
  state: initialState,
  actions: {
    open ({ commit }) {
      commit(mutations.SET, ['show', true])
    },
    close ({ commit, state }) {
      commit(mutations.RESET_STATE, state)
    },
    async submit ({ commit, state }) {
      // append checkbox labels to description
      let checkboxes = ''
      for (const key in state.labels) {
        if (state[key]) {
          checkboxes += state.labels[key] + '\n'
        }
      }
      const payload = {
        user_email: state.user_email,
        user_phone: state.user_phone,
        description: checkboxes + state.description,
        source_url: window.location.href,
        browser_version: window.navigator.appVersion
      }
      await ProblemReportsAPI.reports.create({ data: payload }, false)
    }
  },
  mutations: {
    [mutations.RESET_STATE] (state) {
      Object.assign(state, initialState())
    },
    [mutations.SET] (state, [variable, value]) {
      state[variable] = value
    }
  }
}

export default contactSupportModule
