/**
 * Force-download a file.
 *
 * @param {String} data - file data
 * @param {String} fileName - file name
 * @param {String} ext - file extension
 * @param {String} type - file type
 */
export function downloadFile (data, fileName, ext, type = 'text/plain') {
  const a = document.createElement('a')
  a.style.display = 'none'
  document.body.appendChild(a)

  a.href = window.URL.createObjectURL(new Blob([data], { type }))
  a.setAttribute('download', `${fileName}.${ext}`)
  a.click()

  // Cleanup
  window.URL.revokeObjectURL(a.href)
  document.body.removeChild(a)
}

/**
 * Copy the target element text to clipboard.
 *
 * @param {String} elementId - html element id
 */
export function copyToClipboard (elementId) {
  const target = document.getElementById(elementId)

  if (target === null) {
    throw new Error(`Element with id=${elementId} does not exist.`)
  }

  const range = document.createRange()
  range.selectNode(target)
  window.getSelection().removeAllRanges()
  window.getSelection().addRange(range)
  document.execCommand('copy')
  window.getSelection().removeAllRanges()
}
