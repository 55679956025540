const mutations = {
  RESET_STATE: 'resetState',
  TOGGLE: 'toggle'
}

const initialState = () => {
  return {}
}

const reportingModule = {
  namespaced: true,
  state: initialState,
  mutations: {
    [mutations.RESET_STATE] (state) {
      Object.assign(state, initialState())
    },
    [mutations.TOGGLE] (state, variable) {
      state[variable] = !state[variable]
    }
  }
}

export default reportingModule
