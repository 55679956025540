/**
 * Given a two arrays, return any shared elements.
 *
 * @param {Array} arr1
 * @param {Array} arr2
 * @returns {Array}
 */
export function intersection (array1, array2) {
  return array1.filter(value => array2.includes(value))
}
