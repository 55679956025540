import { request } from '@/services/api'
import { MissingIdError, MissingPayloadError } from '@/services/api/errors'

const REQUIRE_PAYLOAD = ['post', 'put', 'patch']

/**
 * APIBuilder
 *
 * Generates a REST api service for the given url.
 */
class APIBuilder {
  /**
   * Instantiate the builder.
   *
   * If no endpoints are specified, these defaults will be
   * created for you:
   *
   * list: GET
   * retrieve: GET
   * create: POST
   * update: PUT
   * partialUpdate: PATCH
   * destroy: DELETE
   *
   * Alternatively, you can create custom endpoints by providing an
   * endpoint schema as follows:
   *
   * {
   *   name: String,          // invocation method
   *   method: String,        // http verb
   *   path: String           // custom route path
   *   idRequired: Boolean    // enforces id existence
   * }
   *
   * Endpoints definitions can be mixed. For example, if you want
   * to create default endpoints for fetching data in addition to
   * a custom route:
   *
   * new APIBuilder({
   *   baseUrl: 'foo',
   *   endpoints: [
   *     { name: 'list' },
   *     { name: 'retrieve' },
   *     { name: 'custom', method: 'put', path: 'bar/:id', idRequired: true }
   *   ]
   * })
   *
   * This will create a schema that looks like this:
   *
   * {
   *   list: () = {...},         // GET /foo
   *   retrieve: id => {...}     // GET /foo/:id
   *   custom: id => {...}       // PUT /foo/bar/:id
   * }
   *
   * @param {Object} config - configuration object
   * @param {String} config.baseUrl - base endpoint url
   * @param {String|Array} [config.endpoints] - list of endpoints to create, or '*'
   * @param {Object} [config.serializers] - list of endpoints to create
   */
  constructor (config = {}) {
    const {
      baseUrl = null,
      endpoints = '*',
      serializers = {}
    } = config

    if (!baseUrl) {
      throw new Error('APIBuilder: baseUrl is required')
    }

    this.baseUrl = this._trim(baseUrl)
    this.endpoints = endpoints
    this.serializers = serializers
  }

  /**
   * Trim leading and trailing slashes.
   *
   * @param {String} path
   */
  _trim (path) {
    if (path.startsWith('/')) {
      path = path.slice(1)
    }
    if (path.endsWith('/')) {
      path = path.slice(0, -1)
    }
    return path
  }

  /**
   * Construct a standard REST url.
   *
   * @param {String} [id] - resource identifier
   */
  _buildUrl (id) {
    return id ? `/${this.baseUrl}/${id}/` : `/${this.baseUrl}/`
  }

  /**
   * Construct a custom url from the given path.
   *
   * @param {String} path - custom route
   * @param {String} [id] - resource identifier
   */
  _buildUrlFromPath (path, id) {
    if (id) {
      path = this._trim(path).replace(':id', id)
    }
    return `/${this.baseUrl}/${path}/`
  }

  /**
   * Create an endpoint configuration object which
   * can be fed into createEndpoint.
   *
   * Adds serializers, if defined.
   *
   * @param {Object} endpoint
   */
  _getConfig (endpoint) {
    let config = null

    switch (endpoint.name) {
      case 'list':
        config = { method: 'get' }
        break
      case 'retrieve':
        config = { method: 'get', idRequired: true }
        break
      case 'create':
        config = { method: 'post' }
        break
      case 'update':
        config = { method: 'put', idRequired: true }
        break
      case 'partialUpdate':
        config = { method: 'patch', idRequired: true }
        break
      case 'destroy':
        config = { method: 'delete', idRequired: true }
        break
      default:
        // Custom endpoint
        config = {
          method: endpoint.method,
          idRequired: endpoint.idRequired,
          path: endpoint.path
        }
    }

    if (this.serializers.hasOwnProperty(endpoint.name)) {
      config.serializers = this.serializers[endpoint.name]
    }

    return config
  }

  /**
   * De/serializes the request/response data.
   *
   * @param {Object} serializers
   * @param {String} action - 'serialize' or 'deserialize'
   */
  _serialize (serializers, action, data) {
    if (serializers.hasOwnProperty(action)) {
      if (!data) {
        throw new MissingPayloadError()
      }
      return Array.isArray(data) ? data.map(el => serializers[action](el)) : serializers[action](data)
    }
  }

  /**
   * Creates a method to call an api endpoint.
   *
   * @param {String} method - http verb
   * @param {Boolean} [idRequired] - validate id exists
   * @param {String} [path] - custom route path, appended to baseUrl
   * @param {Object} [serialzers] - de/serialize functions
   */
  createEndpoint ({ method, idRequired = false, path, serializers }) {
    return async (config = {}, handleErrors = true) => {
      let { id, data, params } = config

      if (idRequired && !id) {
        throw new MissingIdError()
      }
      if (REQUIRE_PAYLOAD.includes(method) && !data) {
        throw new MissingPayloadError()
      }

      let url = ''

      if (path) {
        url = this._buildUrlFromPath(path, id)
      } else {
        url = this._buildUrl(id)
      }

      if (serializers) {
        data = this._serialize(serializers, 'serialize', data)
      }

      const response = await request({ method, url, data, params }, handleErrors)

      if (serializers) {
        // Store original payload under new key
        response.raw = JSON.parse(JSON.stringify(response.data))
        response.data = this._serialize(serializers, 'deserialize', response.data)
      }

      return response
    }
  }

  /**
   * Generate the api service schema.
   *
   * @returns {Object}
   */
  generateSchema () {
    if (this.endpoints === '*') {
      return {
        list: this.createEndpoint(this._getConfig({ name: 'list' })),
        retrieve: this.createEndpoint(this._getConfig({ name: 'retrieve' })),
        create: this.createEndpoint(this._getConfig({ name: 'create' })),
        update: this.createEndpoint(this._getConfig({ name: 'update' })),
        partialUpdate: this.createEndpoint(this._getConfig({ name: 'partialUpdate' })),
        destroy: this.createEndpoint(this._getConfig({ name: 'destroy' }))
      }
    }

    const endpoints = {}

    for (const endpoint of this.endpoints) {
      endpoints[endpoint.name] = this.createEndpoint(this._getConfig(endpoint))
    }

    return endpoints
  }
}

export default APIBuilder
