import router from '@/router'

const mutations = {
  RESET_STATE: 'resetState',
  SET: 'set'
}

const initialState = () => {
  return {
    error: null,
    showModal: false
  }
}

const errorModule = {
  namespaced: true,
  state: initialState,
  actions: {
    show ({ commit, state }, payload) {
      const error = payload
      if (error) {
        commit(mutations.SET, ['error', error])
        if (error.status === 404) {
          router.replace({ name: 'Page404' })
        } else {
          commit(mutations.SET, ['showModal', true])
        }
      }
    },
    close ({ commit, state }) {
      commit(mutations.RESET_STATE, state)
    }
  },
  mutations: {
    [mutations.RESET_STATE] (state) {
      Object.assign(state, initialState())
    },
    [mutations.SET] (state, [variable, value]) {
      state[variable] = value
    }
  }
}

export default errorModule
