import APIBuilder from '@/services/api/builder'

const ProblemReportsAPI = {
  reports: new APIBuilder({
    baseUrl: 'problem-reports/reports',
    endpoints: [
      { name: 'create' }
    ]
  }).generateSchema()
}

export default ProblemReportsAPI
