/**
 * Given a number, format it as a currency.
 *
 * 123456.789 -> "$123,456.78"
 *
 * @param {Number} value
 * @param {*} defaultValue
 * @param extraOptions: Allows for any additional arguments to be passed to toLocaleString
 * @returns {String}
 */
export function formatCurrency (value, defaultValue = '', extraOptions = {}) {
  if (!value) {
    return defaultValue
  }
  return value.toLocaleString('en-US', { style: 'currency', currency: 'USD', ...extraOptions })
}
