/**
 * Core utility functions.
 */

/**
 * Given a value, determine if it's null.
 *
 * @param {*} value
 * @returns {Boolean}
 */
export function isNull (value) {
  return value === null
}

/**
 * Given a value, determine if it's a number.
 *
 * @param {*} value
 * @returns {Boolean}
 */
export function isNumeric (value) {
  return !isNaN(value - parseFloat(value))
}

/**
 * Given a value, determine if it's a string.
 *
 * @param {*} value
 * @returns {Boolean}
 */
export function isString (value) {
  return typeof value === 'string' || value instanceof String
}

/**
 * Given a value, determine if it's an empty string.
 *
 * @param {*} value
 * @returns {Boolean}
 */
export function isEmptyString (value) {
  return value === ''
}

/**
 * Given an object, determine if it's empty.
 *
 * @param {Object} value
 * @returns {Boolean}
 */
export function isEmptyObject (value) {
  return Object.keys(value).length === 0
}

/**
 * Given a value, determine if it's an object.
 *
 * @param {*} value
 * @param {Boolean} strict - strict object check (not array)
 * @returns {Boolean}
 */
export function isObject (value, strict = false) {
  const isObject = value === Object(value)
  return strict ? isObject && !Array.isArray(value) : isObject
}

/**
 * Given a value, determine if it's a Boolean.
 *
 * @param {*} value
 * @returns {Boolean}
 */
export function isBoolean (value) {
  return typeof value === 'boolean'
}

/**
 * Efficiently shallow-clone an object.
 *
 * Caveat: doesn't copy nested references
 *
 * @param {Object} obj
 */
export function shallowCopy (obj) {
  return Object.assign({}, obj)
}

/**
 * Deep-clone an object.
 *
 * Caveat: potential data loss
 *
 * This will not copy complex types as
 * they are not json-serializable.
 *
 * It's also slow with large objects.
 *
 * @param {Object} obj
 * @returns {Object}
 */
export function deepCopy (obj) {
  return JSON.parse(JSON.stringify(obj))
}

/**
 * Given an object, determine if all the provided
 * keys exists as a direct property of that object.
 *
 * @param {Object} obj
 * @param {Array} keys
 * @returns {Boolean}
 */
export function keysInObject (obj, keys) {
  for (const key of keys) {
    if (!obj.hasOwnProperty(key)) {
      return false
    }
  }
  return true
}

/**
 * Filter blank values ('') and trims leading/training
 * whitespace from string array.
 *
 * @param {Array} array
 * @returns {(Array|null)}
 */
export function filterBlankValues (array) {
  if (!Array.isArray(array)) {
    throw new TypeError(`Object must be an array (received type: ${typeof array})`)
  }
  return array.filter(el => {
    if (!isString(el)) {
      throw new TypeError(`Received non-string value (${typeof el})`)
    }
    return !isEmptyString(el)
  }).map(el => el.trim())
}

/**
 * Convert comma-separated string into an array.
 * Removes empty values and trims whitespace.
 *
 * @param {String} str
 * @returns {Array}
 */
export function csvStringToArray (str) {
  if (!str || typeof (str) !== 'string') {
    return []
  }
  return str.split(',').filter(el => el).map(el => el.trim())
}

/**
 * Filters an object to only contain the allowed key(s).
 *
 * @param {Object} src
 * @param {Array} keys
 * @returns {Object}
 */
export function filterObjectByKey (src, keys) {
  return Object.keys(src)
    .filter(key => keys.includes(key))
    .reduce((obj, key) => ({ ...obj, [key]: src[key] }), {})
}

/**
 * Recursive function to flatten a nested object.
 *
 * Arrays of objects are serialized into arrays
 * of strings.
 *
 * Warning: may make your brain melt.
 *
 * @param {Object} obj
 * @param {String} prefix
 * @returns {Object}
 */
export function flattenObject (obj, prefix = '') {
  const reducer = (prev, element) => {
    let value = obj[element]

    if (isObject(value, true)) {
      return { ...prev, ...flattenObject(value, `${prefix}${element}.`) }
    }

    if (Array.isArray(value)) {
      for (let i = 0; i < value.length; i++) {
        if (isObject(value[i])) {
          value[i] = JSON.stringify(value[i])
        }
      }
      value = value.join(', ')
    }

    return { ...prev, ...{ [`${prefix}${element}`]: value } }
  }

  return Object.keys(obj).reduce(reducer, {})
}

/**
 * Given a list of n object arrays and a unique property
 * of those objects, return a new array with only distict
 * values from all arrays.
 *
 * @param {String} prop - unique object property
 * @param {...Array} arrays - object arrays
 * @returns {Array}
 */
export function getDistinctObjsByProp (prop, ...arrays) {
  const map = new Map()

  for (const array of arrays) {
    if (!array) {
      continue
    }
    for (const obj of array) {
      if (!map.has(obj[prop])) {
        map.set(obj[prop], obj)
      }
    }
  }

  return Array.from(map.values())
}
