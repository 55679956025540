import { ExtendableError } from '@/utils/errors'

/* eslint-disable no-proto */

export class MissingTokenError extends ExtendableError {
  constructor (message = 'Unable to complete request: access token is missing') {
    super(message)

    // FIXME: Temporary workaround for https://babeljs.io/docs/en/caveats/#classes
    this.constructor = MissingTokenError
    this.__proto__ = MissingTokenError.prototype
  }
}
export class TokenExpiredError extends ExtendableError {
  constructor (message = 'Unable to complete request: access token is expired') {
    super(message)

    this.constructor = TokenExpiredError
    this.__proto__ = TokenExpiredError.prototype
  }
}
export class MissingIdError extends ExtendableError {
  constructor (message = 'Unable to complete request: missing resource identifier') {
    super(message)

    this.constructor = MissingIdError
    this.__proto__ = MissingIdError.prototype
  }
}
export class MissingPayloadError extends ExtendableError {
  constructor (message = 'Unable to complete request: payload is required') {
    super(message)

    this.constructor = MissingPayloadError
    this.__proto__ = MissingPayloadError.prototype
  }
}
