import address from './address'
import rooof from './rooof'

/**
 * Shared constants.
 */
const constants = {
  address,
  rooof
}

export default constants
