export const exporting = [
  '17 15',
  `<defs>
     <style>.a{stroke:#fdfdfd;stroke-width:0.5px;}</style>
  </defs>
  <g transform="translate(0.25 -31.738)">
    <g transform="translate(0 33.018)">
      <path class="a" d="M11.217,71.172v4.047H2.039V66.041H5.1V64H1.02A1.02,1.02,0,0,0,0,65.022V76.238a1.019,1.019,0,0,0,1.02,1.02H12.236a1.019,1.019,0,0,0,1.02-1.02V71.172Z" transform="translate(0 -64.002)"/>
    </g>
    <g transform="translate(4.079 31.998)">
      <path class="a" d="M140.033,35.16,135.954,32.1a.51.51,0,0,0-.816.408v1.53h-1.53A5.614,5.614,0,0,0,128,39.646a.51.51,0,0,0,.966.228l.12-.24a4.563,4.563,0,0,1,4.1-2.538h1.948v1.53a.51.51,0,0,0,.816.408l4.079-3.059a.51.51,0,0,0,0-.816Z" transform="translate(-128 -31.998)"/>
    </g>
  </g>`
]
