import { deepCopy, isEmptyString } from '@/utils'
import { MissingRequiredValueError } from '@/utils/errors'

/**
 * Rooof property activation serializer.
 */
const activateProperty = {

  create: {
    serialize: data => {
      if (!data) {
        throw new MissingRequiredValueError('data')
      }
      const dataCopy = deepCopy(data)

      if (dataCopy.hasOwnProperty('agreement_email') && isEmptyString(dataCopy.agreement_email)) {
        delete dataCopy.agreement_email
      }
      if (dataCopy.hasOwnProperty('comments') && isEmptyString(dataCopy.comments)) {
        delete dataCopy.comments
      }

      return dataCopy
    }
  }
}

export default activateProperty
