import APIBuilder from '@/services/api/builder'

import serializers from '../serializers'

const CraigslistAPI = {
  properties: new APIBuilder({
    baseUrl: 'craigslist/properties',
    endpoints: [
      { name: 'list' },
      { name: 'retrieve' },
      { name: 'partialUpdate' },
      { name: 'portalUpdate', method: 'patch', path: ':id/portal-update', idRequired: true }
    ],
    serializers: serializers.craigslist.property
  }).generateSchema(),

  groups: new APIBuilder({
    baseUrl: 'craigslist/postengine-groups',
    endpoints: [
      { name: 'list' }
    ]
  }).generateSchema(),

  accounts: new APIBuilder({
    baseUrl: 'craigslist/accounts',
    endpoints: [
      { name: 'retrieve' }
    ]
  }).generateSchema(),

  regions: new APIBuilder({
    baseUrl: 'craigslist/regions',
    endpoints: [
      { name: 'list' }
    ]
  }).generateSchema()
}

export default CraigslistAPI
