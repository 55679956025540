import Constant from './base'

class State extends Constant {
  static get AL () { return 'Alabama' }
  static get AK () { return 'Alaska' }
  static get AS () { return 'American Samoa' }
  static get AZ () { return 'Arizona' }
  static get AR () { return 'Arkansas' }
  static get CA () { return 'California' }
  static get CO () { return 'Colorado' }
  static get CT () { return 'Connecticut' }
  static get DE () { return 'Delaware' }
  static get DC () { return 'District Of Columbia' }
  static get FM () { return 'Federated States Of Micronesia' }
  static get FL () { return 'Florida' }
  static get GA () { return 'Georgia' }
  static get GU () { return 'Guam' }
  static get HI () { return 'Hawaii' }
  static get ID () { return 'Idaho' }
  static get IL () { return 'Illinois' }
  static get IN () { return 'Indiana' }
  static get IA () { return 'Iowa' }
  static get KS () { return 'Kansas' }
  static get KY () { return 'Kentucky' }
  static get LA () { return 'Louisiana' }
  static get ME () { return 'Maine' }
  static get MH () { return 'Marshall Islands' }
  static get MD () { return 'Maryland' }
  static get MA () { return 'Massachusetts' }
  static get MI () { return 'Michigan' }
  static get MN () { return 'Minnesota' }
  static get MS () { return 'Mississippi' }
  static get MO () { return 'Missouri' }
  static get MT () { return 'Montana' }
  static get NE () { return 'Nebraska' }
  static get NV () { return 'Nevada' }
  static get NH () { return 'New Hampshire' }
  static get NJ () { return 'New Jersey' }
  static get NM () { return 'New Mexico' }
  static get NY () { return 'New York' }
  static get NC () { return 'North Carolina' }
  static get ND () { return 'North Dakota' }
  static get MP () { return 'Northern Mariana Islands' }
  static get OH () { return 'Ohio' }
  static get OK () { return 'Oklahoma' }
  static get OR () { return 'Oregon' }
  static get PW () { return 'Palau' }
  static get PA () { return 'Pennsylvania' }
  static get PR () { return 'Puerto Rico' }
  static get RI () { return 'Rhode Island' }
  static get SC () { return 'South Carolina' }
  static get SD () { return 'South Dakota' }
  static get TN () { return 'Tennessee' }
  static get TX () { return 'Texas' }
  static get UT () { return 'Utah' }
  static get VT () { return 'Vermont' }
  static get VI () { return 'Virgin Islands' }
  static get VA () { return 'Virginia' }
  static get WA () { return 'Washington' }
  static get WV () { return 'West Virginia' }
  static get WI () { return 'Wisconsin' }
  static get WY () { return 'Wyoming' }
}

class Province extends Constant {
  static get AB () { return 'Alberta' }
  static get BC () { return 'British Columbia' }
  static get MB () { return 'Manitoba' }
  static get NB () { return 'New Brunswick' }
  static get NL () { return 'Newfoundland and Labrador' }
  static get NT () { return 'Northwest Territories' }
  static get NS () { return 'Nova Scotia' }
  static get NU () { return 'Nunavut' }
  static get ON () { return 'Ontario' }
  static get PE () { return 'Prince Edward Island' }
  static get SK () { return 'Saskatchewan' }
  static get QC () { return 'Quebec' }
  static get YT () { return 'Yukon' }
}

class Country extends Constant {
  static get CANADA () { return 'Canada' }
  static get UNITED_STATES () { return 'United States' }
}

export default Object.freeze({
  State,
  Province,
  Country
})
