import APIBuilder from '@/services/api/builder'

const ReportingAPI = {
  ...new APIBuilder({
    baseUrl: 'reports',
    endpoints: [
      { name: 'list' },
      { name: 'dashboard', method: 'get', path: 'dashboard' }
    ]
  }).generateSchema(),

  company: new APIBuilder({
    baseUrl: 'reports/companies/',
    endpoints: [
      { name: 'performance', method: 'get', path: ':id/leads', idRequired: true }
    ]
  }).generateSchema(),

  property: new APIBuilder({
    baseUrl: 'reports/properties/v2',
    endpoints: [
      { name: 'posting_activity', method: 'get', path: ':id/posting-activity', idRequired: true },
      { name: 'calls', method: 'get', path: ':id/calls', idRequired: true },
      { name: 'emails', method: 'get', path: ':id/emails', idRequired: true },
      { name: 'redirect_links', method: 'get', path: ':id/redirect-links', idRequired: true },
      { name: 'overview', method: 'get', path: ':id/overview', idRequired: true }
    ]
  }).generateSchema(),

  kpi: new APIBuilder({
    baseUrl: 'reports/kpi',
    endpoints: [
      { name: 'list' },
      { name: 'mrr', method: 'get', path: 'mrr' },
      { name: 'property', method: 'get', path: 'property' },
      { name: 'revenue', method: 'get', path: 'revenue' }
    ]
  }).generateSchema(),

  monthlySummary: new APIBuilder({
    baseUrl: 'reports/summary-report',
    endpoints: [
      { name: 'list' }
    ]
  }).generateSchema(),

  priceUpdating: new APIBuilder({
    baseUrl: 'reports/price-updating',
    endpoints: [
      { name: 'overview', method: 'get', path: 'company-overview' },
      { name: 'companySummary', method: 'get', path: ':id/company-summary' },
      { name: 'propertySummary', method: 'get', path: ':id/property-summary' }
    ]
  }).generateSchema()
}

export default ReportingAPI
