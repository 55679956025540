const mutations = {
  RESET_STATE: 'resetState',
  SET: 'set',
  SHOW_NOTIFICATION: 'show'
}

const initialState = () => {
  return {
    message: '',
    type: 'info',
    show: false
  }
}

const notificationModule = {
  namespaced: true,
  state: initialState,
  mutations: {
    [mutations.RESET_STATE] (state) {
      Object.assign(state, initialState())
    },
    [mutations.SET] (state, [variable, value]) {
      state[variable] = value
    },
    [mutations.SHOW_NOTIFICATION] (state, payload) {
      const {
        message,
        type = 'info',
        duration = 3
      } = payload

      state['message'] = message
      state['type'] = type
      state['show'] = duration
    }
  }
}

export default notificationModule
