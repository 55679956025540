import router from '@/router'
import { RooofAccountAPI } from '@/services/api/resources'
import AuthClient from '@/services/auth'

const mutations = {
  RESET_STATE: 'resetState',
  SET_AUTH_SESSION: 'setAuthSession',
  SET: 'set'
}

const initialState = () => {
  return {
    accessToken: '',
    expiresAt: null,
    scope: '',
    csrfToken: '',
    account: null,
    redirect: ''
  }
}

/**
 * Vuex authentication module.
 *
 * Contains all authentication-related state and actions.
 *
 * Basic login flow:
 *  1. LogIn component dispatches 'login'
 *  2. Persisted state is reset
 *  3. CSRF token is generated and stored
 *  4. User is redirected to OAuth server for authentication
 *  5. User authenticates
 *  6. OAuth server redirects back to redirect_uri with token (or error)
 *  7. Callback component dispatches 'createAuthSession'
 *  8. Response URI is parsed for token or error message
 *  8a. If successful, token is stored in state
 *  8b. If not successful, action returns rejected promise so it can be handled by callback component
 */
const authModule = {
  namespaced: true,
  state: initialState,
  getters: {
    accessToken: state => {
      return state.accessToken
    },
    expiresAt: state => {
      return state.expiresAt
    },
    isAuthenticated: state => {
      if (!(state.accessToken && state.expiresAt)) {
        return false
      }
      return new Date().getTime() < state.expiresAt
    },
    isStaff: state => {
      return state.account && state.account.is_staff
    },
    isAdmin: state => {
      return state.account && state.account.is_superuser
    },
    hasCompanyPermission: state => cid => {
      if (!state.account) {
        return false
      }
      if (state.account.is_staff) {
        return true
      }
      return state.account.postengine_groups.some(membership => {
        return membership.group.company.id === cid
      })
    },
    account: state => {
      return state.account
    }
  },
  actions: {
    login ({ commit, state }) {
      // Clear existing auth session
      commit(mutations.RESET_STATE)

      // Store the redirect path, if set and valid
      const redirectPath = router.currentRoute.query.redirect
      if (redirectPath) {
        const { resolved } = router.resolve(redirectPath)
        if (resolved.name !== 'Page404') {
          commit(mutations.SET, ['redirect', redirectPath])
        }
      }

      // Generate csrf token
      const token = AuthClient.generateRandomID()
      commit(mutations.SET, ['csrfToken', token])

      // Redirect user to oauth login page
      AuthClient.login(state.csrfToken)
    },
    logout ({ commit, dispatch }) {
      // Reset all modules to default state
      dispatch('resetGlobalState', [], { root: true })

      // Redirect user to oauth login page
      AuthClient.logout()
    },
    createAuthSession ({ commit, state }) {
      return new Promise((resolve, reject) => {
        try {
          const token = AuthClient.parseResponseURI(state.csrfToken)
          commit(mutations.SET_AUTH_SESSION, token)
          resolve()
        } catch (err) {
          reject(err)
        }
      })
    },
    async fetchAccount ({ commit }) {
      const response = await RooofAccountAPI.users.me()
      if (response) {
        commit(mutations.SET, ['account', response.data])
      }
    }
  },
  mutations: {
    [mutations.RESET_STATE] (state) {
      Object.assign(state, initialState())
    },
    [mutations.SET_AUTH_SESSION] (state, token) {
      state.accessToken = token.access_token
      state.expiresAt = token.expires_at
      state.scope = token.scope
    },
    [mutations.SET] (state, [variable, value]) {
      state[variable] = value
    },
    [mutations.CLEAR] (state, variable) {
      const original = initialState()
      state[variable] = original[variable]
    }
  }
}

export default authModule
