/**
 * Given a valid address object, determine if it's empty.
 *
 * @param {Object} address
 * @returns {Boolean}
 */
export function isEmptyAddress (address) {
  return Object.values(address).every(item => !item)
}

/**
 * Given a non-empty address object, determine if it's
 * valid.
 *
 * @param {Object} address
 * @returns {Boolean}
 */
export function isValidAddress (address) {
  const required = ['street', 'city', 'state', 'country', 'postal']
  return required.every(key => address[key])
}

/**
 * Given two address objects, determine if they contain
 * the same values for each key.
 *
 * @param {Object} a1 - address 1
 * @param {Object} a2 - address 2
 * @returns {Boolean}
 */
export function equalAddress (a1, a2) {
  if (Object.keys(a1).length !== Object.keys(a2.length)) {
    return false
  }
  for (const key of Object.keys(a1)) {
    return a1.hasOwnProperty(key) && a2.hasOwnProperty(key) && a1[key] === a2[key]
  }
}

/**
 * Given a valid address object, format
 * it as a human-readable string.
 *
 * @param {Object} address
 * @param {Array} [fields] - fields to include in the response
 * @returns {String}
 */
export function formatAddress (address, fields = ['street', 'city', 'state', 'country', 'postal']) {
  if (!address || isEmptyAddress(address)) {
    return ''
  }
  return Object.keys(address)
    .filter(key => fields.includes(key)) // remove unwanted fields
    .filter(key => address[key]) // remove empty values
    .map(key => address[key])
    .join(', ')
}

/**
 * Create a new address object.
 *
 * @returns {Object}
 */
export function createAddress () {
  return {
    attention_to: '',
    street: '',
    city: '',
    state: '',
    country: '',
    postal: '',
    latitude: null,
    longitude: null
  }
}
