/**
 * Given a string, capitalize the first letter.
 *
 * @param {String} value
 * @returns {String}
 */
export function capitalize (value) {
  const str = value.toString()
  return str.charAt(0).toUpperCase() + str.slice(1)
}

/**
 * Given a string, determine if it's uppercased.
 *
 * @param {String} value
 * @returns {Boolean}
 */
export function isUpperCase (value) {
  return value === value.toUpperCase()
}

/**
 * Given a string, create a slug from that string.
 *
 * No support for non-english characters. If
 * you need something that does, there's an npm
 * package called `slugify` which looks robust.
 *
 * Shamelessly stolen from:
 * https://andrew.stwrt.ca/posts/js-slugify/
 *
 * @param {String} value
 */
export function slugify (value) {
  return value
    .toString()
    .trim()
    .toLowerCase()
    .replace(/\s+/g, '-') // replace spaces with hyphen
    .replace(/[^\w-]+/g, '') // remove any non-word, non-hyphen characters
    .replace(/--+/g, '-') // convert multiple hyphens to a single one
    .replace(/^-+/, '') // remove hyphens from start of the string
    .replace(/-+$/, '') // // remove hyphens from end of the string
}
