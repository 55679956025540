import { CraigslistAPI } from '@/services/api/resources'

const mutations = {
  RESET_STATE: 'resetState',
  SET_REGIONS: 'setRegions'
}

const initialState = () => {
  return {
    regions: []
  }
}

const craigslistModule = {
  namespaced: true,
  state: initialState,
  getters: {
    /**
     * Find a major region matching the given id.
     *
     * @param {Number} id
     * @returns {(Object|undefined)}
     */
    getRegionById: state => id => {
      return state.regions.major_regions.find(region => region.id === id)
    },
    /**
     * Find a sub region matching the given id.
     *
     * @param {Number} id
     * @returns {(Object|undefined)}
     */
    getSubRegionById: state => id => {
      return state.regions.sub_regions.find(region => region.id === id)
    }
  },
  actions: {
    /**
     * Fetch the list of craigslist region objects from the api.
     *
     * @param {Object} params - query parameters
     * @param {Boolean} params.nested - nest sub-regions within parent region
     */
    async fetchRegions ({ commit }, params) {
      const response = await CraigslistAPI.regions.list({ params })
      if (response) {
        commit(mutations.SET_REGIONS, response.data)
      }
    }
  },
  mutations: {
    [mutations.RESET_STATE] (state) {
      Object.assign(state, initialState())
    },
    [mutations.SET_REGIONS] (state, value) {
      state.regions = value
    }
  }
}

export default craigslistModule
