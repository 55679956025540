import CoreUiIcons from './coreui-icons'
import CustomIcons from './custom-icons'
import FontAwesomeIcons from './fa-icons'
import MaterialDesignIcons from './md-icons'

export default {
  ...CoreUiIcons,
  ...MaterialDesignIcons,
  ...CustomIcons,
  ...FontAwesomeIcons
}
