import APIBuilder from '@/services/api/builder'

import serializers from '../serializers'

const RooofAPI = {
  properties: new APIBuilder({
    baseUrl: 'rooof/properties',
    endpoints: [
      { name: 'list' },
      { name: 'retrieve' },
      { name: 'update' },
      { name: 'partialUpdate' },
      { name: 'search', method: 'get', path: 'search' }
    ]
  }).generateSchema(),

  companies: new APIBuilder({
    baseUrl: 'rooof/companies',
    endpoints: [
      { name: 'list' },
      { name: 'retrieve' },
      { name: 'partialUpdate' },
      { name: 'summary', method: 'get', path: 'summary' },
      { name: 'propertySummary', method: 'get', path: ':id/properties/summary', idRequired: true }
    ]
  }).generateSchema(),

  products: new APIBuilder({
    baseUrl: 'rooof/products',
    endpoints: [
      { name: 'list' },
      { name: 'retrieve' }
    ]
  }).generateSchema(),

  features: new APIBuilder({
    baseUrl: 'rooof/features',
    endpoints: [
      { name: 'list' },
      { name: 'retrieve' }
    ]
  }).generateSchema(),

  productSubscriptions: new APIBuilder({
    baseUrl: 'rooof/product_subscriptions',
    endpoints: [
      { name: 'list' }
    ]
  }).generateSchema(),

  featureSubscriptions: new APIBuilder({
    baseUrl: 'rooof/feature_subscriptions',
    endpoints: [
      { name: 'list' }
    ]
  }).generateSchema(),

  activateProperty: new APIBuilder({
    baseUrl: 'rooof/activate-property',
    endpoints: [
      { name: 'create' }
    ],
    serializers: serializers.rooof.activateProperty
  }).generateSchema(),

  recurringInvoices: new APIBuilder({
    baseUrl: 'invoices/recurring_invoices',
    endpoints: [
      { name: 'list' }
    ]
  }).generateSchema(),

  billingChanges: new APIBuilder({
    baseUrl: 'rooof/billing-changes',
    endpoints: [
      { name: 'list' }
    ]
  }).generateSchema()
}

export default RooofAPI
