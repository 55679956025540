import { isUpperCase } from '@/utils/strings'

function getConstants (cls) {
  const constants = []
  for (const property of Object.getOwnPropertyNames(cls)) {
    if (isUpperCase(property)) {
      constants.push(property)
    }
  }
  return constants
}

/**
 * Shared base class for all constants.
 *
 * Defines two methods which can be used to transform
 * static class properties into various useful formats.
 */
class Constant {
  static getAsArray (options = {}) {
    const {
      flat = false,
      inverted = false
    } = options

    if (flat) {
      if (inverted) {
        return getConstants(this)
      }
      return getConstants(this).map(constant => this[constant])
    }
    return getConstants(this).map(constant => {
      if (inverted) {
        return { label: this[constant], value: constant }
      }
      return { label: constant, value: this[constant] }
    })
  }

  static getAsObject (options = {}) {
    const { inverted = false } = options

    return getConstants(this).reduce((obj, constant) => {
      const value = this[constant]
      if (inverted) {
        obj[value] = constant
      } else {
        obj[constant] = value
      }
      return obj
    }, {})
  }
}
export default Constant
